import * as React from "react";

import { MenuSchedule, MenuTwitterWall, MenuWifi } from "../constants";

import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

const ImageButton = styled(ButtonBase)(({ theme }) => ({
  position: "relative",
  minHeight: 200,
  height: 200,
  [theme.breakpoints.down("sm")]: {
    width: "100% !important", // Overrides inline-style
    height: 100,
  },
  "&:hover, &.Mui-focusVisible": {
    zIndex: 1,
    "& .MuiImageBackdrop-root": {
      opacity: 0.15,
    },
    "& .MuiImageMarked-root": {
      opacity: 0,
    },
    "& .MuiTypography-root": {
      border: "4px solid currentColor",
    },
  },
}));

const ImageSrc = styled("span")({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: "cover",
  backgroundPosition: "center 40%",
});

const Image = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.common.white,
}));

const ImageBackdrop = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: theme.palette.common.black,
  opacity: 0.4,
  transition: theme.transitions.create("opacity"),
}));

export default function ButtonWithImage(props) {
  const image = props.image;

  const childen = (
    <>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          minWidth: 300,
          width: "100%",
          borderRadius: "5px",
          overflow: "hidden",
          boxShadow: 5,
          mb: 3,
        }}
      >
        <ImageButton
          focusRipple
          key={image.title}
          style={{
            width: image.width,
          }}
        >
          <ImageSrc style={{ backgroundImage: `url(${image.url})` }} />
          <ImageBackdrop className="MuiImageBackdrop-root" />
          <Image>
            <Typography
              component="span"
              variant="subtitle1"
              color="inherit"
              sx={{
                position: "relative",
                p: 2,
                py: 1,
                border: "2px solid #fff",
                //background: "#fff",
                fontWeight: "bold",
                lineHeight: "20px",
                borderRadius: "72px",
                color: "#fff",
              }}
            >
              {image.title.toUpperCase()}
            </Typography>
          </Image>
        </ImageButton>
      </Box>
    </>
  );

  if (image.link.includes("http")) {
    return (
      <a href={image.link} target="_blank">
        {childen}
      </a>
    );
  }
  return <Link to={image.link}>{childen}</Link>;
}

export const ProgramLink = () => {
  const program = {
    url: "/static/home-programa.jpg",
    title: MenuSchedule,
    width: "100%",
    link: "/scheduler",
  };
  return <ButtonWithImage image={program} />;
};

export const WifiLink = () => {
  const wifi = {
    url: "/static/wifi.jpg",
    title: MenuWifi,
    width: "100%",
    link: "/wifi",
  };
  return <ButtonWithImage image={wifi} />;
};

export const TwitterWallLink = () => {
  const twitterWall = {
    url: "/static/twitter.jpg",
    title: MenuTwitterWall,
    width: "100%",
    link: "/twitter-wall",
  };
  return <ButtonWithImage image={twitterWall} />;
};
