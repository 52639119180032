import {
  Avatar,
  AvatarGroup,
  Button,
  CardActions,
  CardHeader,
  CardMedia,
  Divider,
  Hidden,
  Stack,
} from "@mui/material";
import React, { useEffect } from "react";

import { Box } from "@mui/system";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ReactHtmlParser from "react-html-parser";
import { Talks_active } from "../constants";
import Typography from "@mui/material/Typography";
import { keyframes } from "@mui/system";
import moment from "moment";
import program from "../data/program.json";

const getCardHeader = (slot) => {
  return (
    <CardHeader
      title={
        <>
          <Box
            sx={{
              display: "flex",
              alignItems: "start",
              color: "textColor.main",
            }}
          >
            <Box>
              <Typography
                sx={{
                  mr: 2,
                  color: "textColor.main",
                }}
              >
                {slot.timestart}
              </Typography>
            </Box>
            <Box>
              <Typography>{slot.title}</Typography>
              <Typography
                sx={{
                  color: "textColor.main",
                }}
                variant="caption"
                display="block"
                gutterBottom
              >
                {slot.length} minutos / {slot.venue}
              </Typography>
            </Box>
          </Box>
        </>
      }
    />
  );
};

const getAvatar = (slot) => {
  if (Array.isArray(slot.icon)) {
    return (
      <AvatarGroup>
        {slot.icon.map((avatarSrc, index) => {
          return <Avatar key={index} alt={slot.speaker} src={avatarSrc} />;
        })}
      </AvatarGroup>
    );
  }

  if (slot.speaker) {
    return <Avatar alt={slot.speaker} src={slot.icon ?? slot.speaker} />;
  }
  return;
};

const blink = keyframes`
  50% {
    background-color: rgb(40, 125, 50, 60%);
  }
`;
const getCardActions = (slot) => {
  const isActive = slotIsActive(slot);

  if (!slot.speaker && !isActive) return;

  return (
    <>
      <Divider variant="middle" />
      <CardActions
        sx={[
          { px: 2, py: 1 },
          {
            justifyContent: "flex-end",
          },
          isActive && {
            justifyContent: "space-between",
          },
        ]}
      >
        {isActive && (
          <Button
            sx={{
              animation: `${blink} 2s linear infinite`,
            }}
            size="small"
            color="success"
            variant="contained"
          >
            {Talks_active}
          </Button>
        )}
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}
          sx={{
            bgcolor: "secondary.main",
            paddingRight: 2,
            borderRadius: 10,
          }}
        >
          {getAvatar(slot)}
          <Typography variant="caption" display="block">
            {slot.speaker}
          </Typography>
        </Stack>
      </CardActions>
    </>
  );
};

const slotIsActive = (slot) => {
  const day = moment().format("YYYY-MM-DD");
  const dateNow = moment();

  const slotStarTime = moment(day + " " + slot.timestart);
  const slotFinishTime = moment(day + " " + slot.timestart).add(
    slot.length,
    "minutes"
  );

  return dateNow.isBetween(slotStarTime, slotFinishTime);
};

const getDescription = (slot, showDescription, key) => {
  const description = ReactHtmlParser(
    slot.description.replace(/(?:\r\n|\r|\n)/g, "<br>")
  );

  if (slot.description.replace(/(<([^>]+)>)/gi, "").length < 60) {
    return (
      <Typography
        component={"div"}
        variant="body2"
        sx={{ color: "text.secondary" }}
      >
        {description}
      </Typography>
    );
  }
  return (
    <>
      <Typography
        component={"div"}
        variant="body2"
        sx={{ color: "text.secondary" }}
      >
        {(showDescription[key] && description) ||
          slot.description
            .replace(/(<([^>]+)>)/gi, "")
            .substring(0, 60)
            .trim() + "..."}
      </Typography>
      <div>
        {(showDescription[key] && <ExpandLessIcon />) || <ExpandMoreIcon />}
      </div>
    </>
  );
};

export const Scheduler = () => {
  const [showDescription, setShowDescription] = React.useState({});

  const handleChange = (panel) => (event) => {
    const newState = { [panel]: !showDescription[panel] };

    setShowDescription({
      ...showDescription,
      ...newState,
    });
  };

  useEffect(() => {
    const yOffset = -70;

    const element = document.getElementById("slot-active");
    if (element) {
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({ top: y, behavior: "smooth" });
    }
  }, []);

  return (
    <>
      {program.map((slot, key) => {
        const isActive = slotIsActive(slot);

        return (
          <Card
            key={key}
            sx={{
              boxShadow: 5,
              mb: 3,
            }}
          >
            {isActive && <span key={`ref-${key}`} id={"slot-active"}></span>}
            {slot.media && (
              <CardMedia
                component="img"
                height="140"
                image={slot.media}
                alt=""
              />
            )}
            {getCardHeader(slot)}

            {slot.description && (
              <>
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    onClick={handleChange(key)}
                  >
                    {getDescription(slot, showDescription, key)}
                  </Box>
                </CardContent>
              </>
            )}
            {getCardActions(slot)}
          </Card>
        );
      })}
    </>
  );
};
