import * as React from "react";

import {
  Box,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
  Toolbar,
  Typography,
} from "@mui/material";
import {
  MenuHome,
  MenuSchedule,
  MenuTwitterWall,
  MenuWifi,
  TopbarTitle,
} from "../constants";

import HomeIcon from "@mui/icons-material/Home";
import MenuIcon from "@mui/icons-material/Menu";
import NetworkWifiIcon from "@mui/icons-material/NetworkWifi";
import { Link as RouterLink } from "react-router-dom";
import ScheduleIcon from "@mui/icons-material/Schedule";
import TwitterIcon from "@mui/icons-material/Twitter";
import logo from "../assets/logo--white.png";
import styled from "@emotion/styled";

const Logo = styled.img`
  width: 80px;
  margin-right: 15px;
`;

const menuOptions = () => {
  return [
    {
      text: MenuHome,
      icon: <HomeIcon />,
      link: "/",
    },
    {
      text: MenuSchedule,
      icon: <ScheduleIcon />,
      link: "/scheduler",
    },
    {
      text: MenuTwitterWall,
      icon: <TwitterIcon />,
      link: "/twitter-wall",
    },
    {
      text: MenuWifi,
      icon: <NetworkWifiIcon />,
      link: "/wifi",
    },
  ];
};

export const CustomTopbar = () => {
  const [menuOpenStatus, setMenuOpenStatus] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setMenuOpenStatus(open);
  };

  const list = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {menuOptions().map((menuItem) => (
          <Link
            underline="none"
            color="menu.primary"
            component={RouterLink}
            to={menuItem.link}
            key={menuItem.link}
          >
            <ListItem button>
              <ListItemIcon>{menuItem.icon}</ListItemIcon>
              <ListItemText primary={menuItem.text} />
            </ListItem>
          </Link>
        ))}
      </List>
    </Box>
  );

  return (
    <Toolbar>
      <IconButton
        onClick={toggleDrawer(true)}
        size="large"
        edge="start"
        color="inherit"
        aria-label="menu"
        sx={{ mr: 2 }}
      >
        <MenuIcon />
      </IconButton>
      <Logo src={logo} alt="Biko" />

      <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
        {TopbarTitle}
      </Typography>

      <SwipeableDrawer
        anchor="left"
        open={menuOpenStatus}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        {list("left")}
      </SwipeableDrawer>
    </Toolbar>
  );
};
