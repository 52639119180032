import * as React from "react";

import { Route, Routes } from "react-router-dom";

import { AppBar } from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { CustomTopbar } from "./components/Topbar";
import { Home } from "./views/Home";
import { Scheduler } from "./views/Scheduler";
import { TwitterWall } from "./views/TwitterWall";
import { WifiPage } from "./views/WifiPage";

export const App = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="sticky" enableColorOnDark>
        <CustomTopbar />
      </AppBar>
      <Container maxWidth={false}>
        <Box sx={{ my: 2 }}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/scheduler" element={<Scheduler />} />
            <Route path="/wifi" element={<WifiPage />} />
            <Route path="/twitter-wall" element={<TwitterWall />} />
          </Routes>
        </Box>
      </Container>
    </Box>
  );
};
