import ButtonWithImage, {
  ProgramLink,
  TwitterWallLink,
  WifiLink,
} from "../components/ButtonWithImage";

export const BikoLink = () => {
  const program = {
    url: "/static/biko.jpg",
    title: "Biko",
    width: "100%",
    link: "https://www.biko2.com",
  };
  return <ButtonWithImage image={program} />;
};

export const BikoLabsLink = () => {
  const program = {
    url: "/static/bikolabs.gif",
    title: "Bikolabs",
    width: "100%",
    link: "https://bikolabs.biko2.com/",
  };
  return <ButtonWithImage image={program} />;
};

export const Home = () => {
  return (
    <>
      <ProgramLink />
      <TwitterWallLink />
      <WifiLink />
      {/* <BikoLink />
      <BikoLabsLink /> */}
    </>
  );
};
