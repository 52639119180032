import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";

// A custom theme for this app
const theme = createTheme({
  palette: {
    textColor: {
      main: "#666",
    },
    primary: {
      main: "#e73f39",
    },
    secondary: {
      main: "#dde4e9",
    },
    menu: {
      primary: "rgba(0, 0, 0, 0.54)",
    },
    error: {
      main: red.A400,
    },
    slots: {
      status: {
        active: "rgb(147, 197, 114)",
      },
    },
  },
});

export default theme;
