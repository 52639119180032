import {
  PageWifi_pass,
  PageWifi_pass_data,
  PageWifi_wifi,
  PageWifi_wifi_data,
} from "../constants";
import { Paper, Typography } from "@mui/material";

import { ProgramLink } from "../components/ButtonWithImage";

export const WifiPage = () => {
  return (
    <>
      <Paper
        sx={{
          p: 4,
          pb: 2,
          mb: 4,
        }}
        elevation={12}
      >
        <Typography paragraph={true}>
          <strong>{PageWifi_wifi}:</strong> {PageWifi_wifi_data}
        </Typography>
        <Typography paragraph={true}>
          <strong>{PageWifi_pass}:</strong> {PageWifi_pass_data}
        </Typography>
      </Paper>

      <ProgramLink />
    </>
  );
};
