export const TopbarTitle = "Open Biko XXI";
export const MenuHome = "Home";
export const MenuSchedule = "Programa";
export const MenuTwitterWall = "Twitter Wall";
export const MenuWifi = "Wifi";

export const PageWifi_wifi = "Red";
export const PageWifi_wifi_data = "VIVEROWIRELESS";
export const PageWifi_pass = "Contraseña";
export const PageWifi_pass_data = "Cein2007";

export const Talks_active = "On Air";
