import { Box, CircularProgress, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";

import Masonry from "@mui/lab/Masonry";
import { TwitterTweetEmbed, TwitterHashtagButton } from "react-twitter-embed";
import twitterWallMock from "../dataMocks/twitter-wall.json";

const TWITTER_WALL_API =
  "https://open.biko2.com/.netlify/functions/tweets-by-tag";

async function getTweets() {
  if (location.hostname === "localhost" || location.hostname === "127.0.0.1") {
    console.log("Debug: local api");
    return twitterWallMock;
  }

  return await fetch(TWITTER_WALL_API, {
    headers: {
      Accept: "application/json",
    },
  })
    .then(function (response) {
      return response.json();
    })
    .then(function (data) {
      return data;
    });
}

// const filterRetuits = (tweet) => {
//   if (tweet && tweet.retweeted_status) {
//     return false;
//   }
//   return true;
// };

const getTweetsIds = async () => {
  const tweets = await getTweets();
  if (!tweets || !tweets.content || !tweets.content.statuses) {
    return;
  }

  return (
    tweets.content.statuses
      //.filter((tweet) => filterRetuits(tweet))
      .map((tweet) => {
        return tweet.id_str;
      })
  );
};

export const TwitterWall = () => {
  const [tweets, setTweets] = useState(false);

  useEffect(() => {
    getTweetsIds().then((data) => {
      setTweets(data);
    });

    setInterval(() => {
      console.log("Debug: setInterval - call to api");
      getTweetsIds().then((data) => {
        setTweets(data);
      });
    }, 30000);
  }, []);

  if (!tweets) {
    return (
      <>
        <Stack
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            p: 4,
            minHeight: "500px",
          }}
          direction="row"
        >
          <CircularProgress />
        </Stack>
      </>
    );
  }
  return (
    <>
      <Box sx={{ my: 2 }}>
        <Box sx={{ mr: 1 }}>
          <TwitterHashtagButton
            options={{ size: "large", lang: "es" }}
            tag={"openbiko"}
          />
        </Box>
        <Box>
          <TwitterHashtagButton
            options={{ size: "large", lang: "es" }}
            tag={"CloseCloserOpen"}
          />
        </Box>
      </Box>
      <Masonry columns={{ xs: 1, sm: 2, lg: 3, xl: 5 }} spacing={2}>
        {tweets.map((tweetId) => {
          return <TwitterTweetEmbed key={tweetId} tweetId={tweetId} />;
        })}
      </Masonry>
    </>
  );
};
